import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import Text from "../components/Typography/Text";
// @ts-ignore
import motionImage from "../images/highlight-motion.png";
// @ts-ignore
import connectImage from "../images/highlight-connect.png";
// @ts-ignore
import bastardaImage from "../images/highlight-bastarda.png";
// @ts-ignore
import ifawardImage from "../images/highlight-ifaward.png";
import Item from "../components/Item/Item";
import styled from "styled-components";
import { StyledArticleContainer } from "../components/Article/ArticleStyles";

const StyledIntroWrapper = styled.div`
    display: grid;
    row-gap: 2rem;
    padding-bottom: 4rem;

    @media screen and (min-width: 1024px) {
        padding-bottom: 0;
    }
`;

const StyledHighlight = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
    grid-column: span 2;

    img {
        grid-column: span 1;
    }

    @media screen and (min-width: 600px) {
        grid-column: 2 / span 4;
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1024px) {
        grid-column: 4 / span 9;
    }
`;

const Journey = () => (
    <Layout>
        <Seo title="About Me" />
        <StyledIntroWrapper>
            <Text.Medium>
                It all started with MySpaces and evolved into designing and
                developing interactive products and applications. I'm passionate
                about crafting rich experiences and creating tools that help
                other people optimize the way they work and create. A father of
                one, I love tennis as much as videogames, frame by frame
                animation, and trying new ways to create. I like to believe in
                leading by example, encouraging people by demonstrating it's
                possible.
            </Text.Medium>
            <Text.Small>
                I've worked mainly in animation, cultural, tech and fintech
                industries, evolving from crafting motion design pieces and
                beautiful user interfaces to planning and executing end-to-end
                product design workflows while striving for consistency both
                visually and behaviourally. Managing clients directly and
                working side by side with various stakeholders throughout my
                career as well as sharing my knowledge for others to grow their
                skillsets as designers and creatives through mentorships or
                collective workshops. I also enjoy working in the community side
                of things, encouraging teams to share their work under a design
                brand.
            </Text.Small>
            <Text.Small>
                This site is a work in progress, built with Gatsby using React
                and hosted by Netlify.
            </Text.Small>
        </StyledIntroWrapper>

        <StyledArticleContainer style={{ rowGap: "2rem" }}>
            <h2>Recent highlights</h2>
            <StyledHighlight>
                <img src={ifawardImage} alt="iF Design Award" />
                <Item.Other
                    name="iF Design Award 2022 Winner"
                    description="Our design team at Lulo Bank was awarded with the iF Design Award 2022 in the User Interfaces and Interfaces for Digital Media categories."
                    cta={{
                        text: "View more",
                        url: "https://ifdesign.com/en/winner-ranking/project/lulo-bank/351160"
                    }}
                ></Item.Other>
            </StyledHighlight>
            <StyledHighlight>
                <img src={motionImage} alt="Motion for UX Design Workshop" />
                <Item.Other
                    name="Animation for UX Course"
                    description="I developed and ran a remote internal course to learn about animation in Product Design and how it leverages the user experience. Plus I designed some nice certificates to share. Looking forward to make it public. "
                ></Item.Other>
            </StyledHighlight>
            <StyledHighlight>
                <img src={connectImage} alt="Connect Talk" />
                <Item.Other
                    name="Huge Academy"
                    description="I was invited by Huge Colombia to talk about how I build Connect. Check it out in the link below."
                    cta={{
                        text: "View more",
                        url: "https://youtu.be/TrNj5ll0lj0",
                    }}
                ></Item.Other>
            </StyledHighlight>
            <StyledHighlight>
                <img src={bastardaImage} alt="Connect Talk" />
                <Item.Other
                    name="Latin American Design Awards – Gold"
                    description="Bastarda Type Website Refresh"
                    cta={{
                        text: "View more",
                        url: "https://awards.latinamericandesign.org/winning-projects-detail/?identifier=temp_project_e112c41730_153e275e2c",
                    }}
                ></Item.Other>
            </StyledHighlight>
        </StyledArticleContainer>
    </Layout>
);

export default Journey;
